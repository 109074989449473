.header-image {
  background-image: url('../../assets/images/font-generator-header.svg');
  background-size: cover;
}

.variants-container {
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}

.monospace {
  font-family: monospace!important;
  margin-top: 16px;
}

.text {
  margin-top: 16px;
}

@media screen and (max-width: 1000px) {
  .card {
    width: 90%;
  }
}

.mono {
  font-family:
  "SFMono-Regular",
  Consolas,
  "Liberation Mono",
  Menlo,
  Courier,
  monospace!important;

  pre {
    font-family:
    "SFMono-Regular",
    Consolas,
    "Liberation Mono",
    Menlo,
    Courier,
    monospace!important;
  }
}